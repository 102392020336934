import { Autocomplete } from '@chiroup/components';
import { FormError } from '@chiroup/core';
import React, { useMemo } from 'react';
import useDatabaseAutocomplete from '../../hooks/useDatabaseAutoComplete';

type Props = {
  value?: number;
  onChange: (value: number) => void;
  onEmptyOptions?: () => void;
  error?: FormError;
  searchRef?: React.RefObject<HTMLInputElement>;
  buttonRef?: React.RefObject<HTMLInputElement>;
  className?: string;
  clinicId: number;
  label: string;
  limit?: number;
  hideEndOfList?: boolean;
  unshiftNotListedOption?: boolean;
  language?: string;
};

const DatabaseAutocomplete: React.FC<Props> = ({
  value,
  onChange,
  error,
  searchRef,
  buttonRef,
  onEmptyOptions,
  className,
  clinicId,
  label,
  limit = 10,
  hideEndOfList = false,
  unshiftNotListedOption = false,
  language = 'en',
}) => {
  const {
    options,
    fetchNextPage,
    isFetching,
    onChangeSearch,
    hasNextPage,
    search,
  } = useDatabaseAutocomplete(+clinicId, limit);

  const onSelect = (code: number) => {
    onChange(code);
  };

  const optionsToUse = useMemo(() => {
    if (unshiftNotListedOption) {
      return [
        {
          value: -1,
          text: language === 'es' ? 'No listado' : 'Not listed',
        },
        ...options,
      ];
    } else {
      return options;
    }
  }, [options, unshiftNotListedOption, language]);

  return (
    <Autocomplete
      className={className || 'col-span-4'}
      label={label}
      name="recordId"
      onChange={onSelect}
      value={value}
      errors={error}
      onChangeSearch={onChangeSearch}
      options={optionsToUse}
      onEmptyOptions={onEmptyOptions}
      serverSide
      fetchNextPage={fetchNextPage}
      isFetching={isFetching}
      hasNextPage={hasNextPage}
      limit={1}
      searchTerm={search}
      noneText="Type to search..."
      searchRef={searchRef}
      buttonRef={buttonRef}
      hideEndOfList={hideEndOfList}
    />
  );
};

export default DatabaseAutocomplete;
